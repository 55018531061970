

















































import Vue from 'vue';
import Component from 'vue-class-component';
import { Global } from '@/store';
import { Prop } from 'vue-property-decorator';

@Component({})
export default class TimeClock extends Vue {

    @Global.State('lang') lang;

    @Prop() clock_time;
    @Prop() min;
    @Prop() max;
    @Prop() format;
    @Prop() label;
    @Prop() disabled;

    is_menu_open = false;
    time = '';
    loading = true;

    mounted(){
        this.time = this.clock_time;
        this.loading = false;
    }

    updateClockTime(){
        this.$emit('updateTime', this.time);
    }
}
